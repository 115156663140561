export const CHILD_BY_ID_REQUEST = 'CHILD_BY_ID_REQUEST';
export const CHILD_BY_ID_SUCCESS = 'CHILD_BY_ID_SUCCESS';
export const CHILD_BY_ID_FAIL = 'CHILD_BY_ID_FAIL';
export const CHILD_BY_ID_RESET = 'CHILD_BY_ID_RESET';

export const CHILDREN_BY_NGO_REQUEST = 'CHILDREN_BY_NGO_REQUEST';
export const CHILDREN_BY_NGO_SUCCESS = 'CHILDREN_BY_NGO_SUCCESS';
export const CHILDREN_BY_NGO_FAIL = 'CHILDREN_BY_NGO_FAIL';
export const CHILDREN_BY_NGO_RESET = 'CHILDREN_BY_NGO_RESET';

export const CHILD_ACTIVE_LIST_REQUEST = 'CHILD_ACTIVE_LIST_REQUEST';
export const CHILD_ACTIVE_LIST_SUCCESS = 'CHILD_ACTIVE_LIST_SUCCESS';
export const CHILD_ACTIVE_LIST_FAIL = 'CHILD_ACTIVE_LIST_FAIL';
export const CHILD_ACTIVE_LIST_RESET = 'CHILD_LIST_RESET';

export const CHILD_LIST_REQUEST = 'CHILD_LIST_REQUEST';
export const CHILD_LIST_SUCCESS = 'CHILD_LIST_SUCCESS';
export const CHILD_LIST_FAIL = 'CHILD_LIST_FAIL';
export const CHILD_LIST_RESET = 'CHILD_LIST_RESET';

export const UPDATE_CHILD_REQUEST = 'UPDATE_CHILD_REQUEST';
export const UPDATE_CHILD_PREREGISTER_SUCCESS = 'UPDATE_CHILD_PREREGISTER_SUCCESS';
export const UPDATE_CHILD_SUCCESS = 'UPDATE_CHILD_SUCCESS';
export const UPDATE_CHILD_FAIL = 'UPDATE_CHILD_FAIL';

export const UPDATE_CHILD_STATUS_REQUEST = 'UPDATE_CHILD_STATUS_REQUEST';
export const UPDATE_CHILD_STATUS_SUCCESS = 'UPDATE_CHILD_STATUS_SUCCESS';
export const UPDATE_CHILD_STATUS_FAIL = 'UPDATE_CHILD_STATUS_FAIL';

export const PRE_REGISTER_CHILD_PREPARE_REQUEST = 'PRE_REGISTER_CHILD_PREPARE_REQUEST';
export const PRE_REGISTER_CHILD_PREPARE_SUCCESS = 'PRE_REGISTER_CHILD_PREPARE_SUCCESS';
export const PRE_REGISTER_CHILD_PREPARE_FAIL = 'PRE_REGISTER_CHILD_PREPARE_FAIL';

export const PRE_REGISTER_CHILD_UPDATE_REQUEST = 'PRE_REGISTER_CHILD_UPDATE_REQUEST';
export const PRE_REGISTER_CHILD_UPDATE_SUCCESS = 'PRE_REGISTER_CHILD_UPDATE_SUCCESS';
export const PRE_REGISTER_CHILD_UPDATE_FAIL = 'PRE_REGISTER_CHILD_UPDATE_FAIL';

export const PRE_REGISTER_CHILD_ADD_REQUEST = 'PRE_REGISTER_CHILD_ADD_REQUEST';
export const PRE_REGISTER_CHILD_ADD_SUCCESS = 'PRE_REGISTER_CHILD_ADD_SUCCESS';
export const PRE_REGISTER_CHILD_ADD_FAIL = 'PRE_REGISTER_CHILD_ADD_FAIL';

export const PRE_REGISTER_CHILD_LIST_REQUEST = 'PRE_REGISTER_CHILD_LIST_REQUEST';
export const PRE_REGISTER_CHILD_LIST_SUCCESS = 'PRE_REGISTER_CHILD_LIST_SUCCESS';
export const PRE_REGISTER_CHILD_LIST_FAIL = 'PRE_REGISTER_CHILD_LIST_FAIL';
export const PRE_REGISTER_CHILD_LIST_REST = 'PRE_REGISTER_CHILD_LIST_RESET';

export const PRE_REGISTER_CHILD_REQUEST = 'PRE_REGISTER_CHILD_REQUEST';
export const PRE_REGISTER_CHILD_SUCCESS = 'PRE_REGISTER_CHILD_SUCCESS';
export const PRE_REGISTER_CHILD_FAIL = 'PRE_REGISTER_CHILD_FAIL';
export const PRE_REGISTER_CHILD_REST = 'PRE_REGISTER_CHILD_RESET';

export const CHECK_SIMILAR_NAMES_REQUEST = 'CHECK_SIMILAR_NAMES_REQUEST';
export const CHECK_SIMILAR_NAMES_SUCCESS = 'CHECK_SIMILAR_NAMES_SUCCESS';
export const CHECK_SIMILAR_NAMES_FAIL = 'CHECK_SIMILAR_NAMES_FAIL';
export const CHECK_SIMILAR_NAMES_RESET = 'CHECK_SIMILAR_NAMES_RESET';

export const ADD_CHILD_REQUEST = 'ADD_CHILD_REQUEST';
export const ADD_CHILD_SUCCESS = 'ADD_CHILD_SUCCESS';
export const ADD_CHILD_FAIL = 'ADD_CHILD_FAIL';

export const APPROVE_PRE_REGISTER_REQUEST = 'APPROVE_PRE_REGISTER_REQUEST';
export const APPROVE_PRE_REGISTER_SUCCESS = 'APPROVE_PRE_REGISTER_SUCCESS';
export const APPROVE_PRE_REGISTER_FAIL = 'APPROVE_PRE_REGISTER_FAIL';
export const APPROVE_PRE_REGISTER_RESET = 'APPROVE_PRE_REGISTER_RESET';

export const DELETE_PRE_REGISTER_REQUEST = 'DELETE_PRE_REGISTER_REQUEST';
export const DELETE_PRE_REGISTER_SUCCESS = 'DELETE_PRE_REGISTER_SUCCESS';
export const DELETE_PRE_REGISTER_FAIL = 'DELETE_PRE_REGISTER_FAIL';
export const DELETE_PRE_REGISTER_RESET = 'DELETE_PRE_REGISTER_RESET';

export const SAY_NAMES_REQUEST = 'SAY_NAMES_REQUEST';
export const SAY_NAMES_SUCCESS = 'SAY_NAMES_SUCCESS';
export const SAY_NAMES_FAIL = 'SAY_NAMES_FAIL';
export const SAY_NAMES_RESET = 'SAY_NAMES_RESET';
